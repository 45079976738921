$main-purple: #7473bc;
$main-blue: #2C3F6B;

body {
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    color: $main-blue;
    
    h1 {
        font-weight: 400;
        font-size: 4rem;
    }

    h2 {
        text-align: center;
        font-size: 3.75rem;
        font-weight: 500;
    }

    h3 {
        font-size:2.125rem;
        line-height: 1.7rem;
    }

    h4 {
        font-weight: 300;
        font-size: 2.125rem;
    }

    h5 {
        font-weight: 400;
        font-size: 1.5rem; 
    }

    p {
        font-weight: normal;
        line-height: 1.5rem;
        text-align: center;
    }

    button {
        border: none;
        font-weight: 400;
        font-size: 1.125rem;
        text-align: center;
        cursor: pointer;

        &.main-btn {
            background: $main-purple;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 100px;
            border: none;
            color: white;
            padding: 0.7em 2.5em;
            width: fit-content;
            align-self: center;
        }
    
        &.secondary-btn {
            background: $main-blue;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 100px;
            padding: 0.7em 2.5em;
    
            &.white {
                background: transparent;
                box-sizing: border-box;
                border-radius: 100px;
                border: 2px solid #F2F2F2;
                color: #F2F2F2;
                padding: 0.7em 1em;
            }
        }
    }

}
