.join-meeting-field {
    display:flex;
    flex-direction: row;
    height: 2.8925rem;
    margin-bottom:5em;

    .join-meeting-input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border: 1px solid #7473BC;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        &.error {
            border: 1.5px solid #FF7879;
        }
    
        .join-meeting-input-field {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            box-sizing: border-box;
            font-size: 1rem;
            border: 0;
            height: 100%;
            margin-top:0px;
            background: transparent;
            text-align: center;
            max-width: 10rem;
            
            &::placeholder {
                color: gray;
                font-style: italic;
            } 
        }    
    }

    .join-meeting-btn {
        width:100%;
        height: 100%;
        background-color: #7473BC;
        color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 1.125rem;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        cursor: pointer;
        vertical-align: top;
    }
}